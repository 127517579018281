import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useWhiteLabelling } from 'hooks/data/whiteLabelling/useWhiteLabelling';
import { Api } from 'further-types/white-labelling';
import setTheme from 'helpers/layout/setTheme';
import { FrontEndSiteUrlToStageMap } from 'constants/redirects';
import configure from 'constants/config';
import addCustomFavicons from 'utils/addCustomFavicons';
import { Fund } from 'hooks/data/fund/useFundBySlug';
import { UseQueryResult } from '@tanstack/react-query';

type WhiteLabellingContextType = {
  firm: Api.WhiteLabellingResponse['firm'] | null;
  fund: any; //TODO: use Fund type once it is added to the API
  updateFund: (fund: any) => void; //TODO: use Fund type once it is added to the API
  isLoading: boolean;
  isSubdomain: boolean;
  status: UseQueryResult['status'];
};

export const WhiteLabellingContext = createContext<WhiteLabellingContextType>({
  firm: null,
  fund: null,
  updateFund: () => {},
  isLoading: true,
  isSubdomain: false,
  status: 'pending',
});

const performAdviserSubdomainRedirect = () => {
  setTheme(false);
  if (window.location.pathname === '/') {
    window.open(
      `${FrontEndSiteUrlToStageMap[configure.STAGE]}/adviser-entry`,
      '_self',
    );
  }
};

const addCookieBanner = () => {
  const script = document.createElement('script');
  script.id = 'cookieyes';
  script.src =
    'https://cdn-cookieyes.com/client_data/f503be260df045342a516f16/script.js';
  document.body.appendChild(script);
};

export const WhiteLabellingProvider = ({ children }) => {
  const [isSubdomain, setIsSubdomain] = useState(false);
  const [fund, setFund] = useState<Fund | null>(null);

  const domain = useMemo(() => {
    const url = new URL(window.location.href);
    return url.hostname.split('.')[0];
  }, []);

  const { data, isLoading, status } = useWhiteLabelling({ domain });

  useEffect(() => {
    if (domain?.length) {
      switch (domain) {
        case configure.STAGE:
        case 'localhost':
          setTheme(false);
          setIsSubdomain(false);
          break;
        case 'www':
          setTheme(false);
          setIsSubdomain(false);
          if (configure.STAGE === 'production') {
            addCookieBanner();
          }
          break;
        case 'adviser':
          performAdviserSubdomainRedirect();
          setIsSubdomain(false);
          break;
        default:
          if (!data?.firm && status === 'success') {
            window.open(FrontEndSiteUrlToStageMap[configure.STAGE], '_self');
          } else if (data?.firm) {
            setIsSubdomain(true);
            setTheme(true, data?.firm?.whiteLabelConfig);
            addCustomFavicons(data?.firm);
          }
          break;
      }
    }
  }, [data]);

  const contextValue = {
    firm: data?.firm ?? null,
    isLoading,
    status,
    isSubdomain,
    fund,
    updateFund: setFund,
  };

  return (
    <WhiteLabellingContext.Provider value={contextValue}>
      {children}
    </WhiteLabellingContext.Provider>
  );
};

export const useWhiteLabellingContext = () => {
  return useContext(WhiteLabellingContext);
};

export default WhiteLabellingProvider;
